import type { InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { isWuAppWebview } from '@libs-components/utils/device'
import Homepage from './home'
import getStaticProps from './home/getStaticProps'

type PageProps = InferGetStaticPropsType<typeof getStaticProps>
const Home = ({ pageComponents }: PageProps) => {
  const router = useRouter()
  if (isWuAppWebview()) {
    router.replace('/app-home')
  }

  return (
    <>
      <NextSeo canonical={process.env.NEXT_PUBLIC_SELF_DOMAIN} />
      <Homepage pageComponents={pageComponents?.data} />
    </>
  )
}

export default Home
export { getStaticProps }
